<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5">
      <v-row no-gutters>
        <v-col cols="12" md="9">
          <div class="mt-2 txt_search">
            <v-text-field
              v-model="find"
              hide-details
              solo
              flat
              background-color="#CFE1E1"
              class="rounded-lg"
              placeholder="Search for people"
              prepend-inner-icon="mdi-magnify"
              @keyup="fetchData"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="3" class="d-flex justify-end">
          <!-- <v-row no-gutters align="center" justify="flex-end">
            <v-col cols="12" md="6"> -->
          <div class="mt-2 mx-2 d-flex align-center justify-end">
            <h4 class="mr-2">Show</h4>
            <v-menu
              v-model="menuLimit"
              elevation="0"
              transition="slide-y-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  min-width="60px"
                  max-width="60px"
                  outlined
                  depressed
                  class="text-capitalize"
                  v-bind="attrs"
                  v-on="on"
                >
                  <p class="ma-0 default_txt">{{ limit }}</p>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card class="popup_radius" outlined elevation="0">
                <v-list>
                  <v-list-item link @click="changeLimit(10)">
                    <v-list-item-title>10</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="changeLimit(50)">
                    <v-list-item-title>50</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="changeLimit(100)">
                    <v-list-item-title>100</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
          <!-- </v-col> -->
          <!-- <v-col cols="12" md="6">
              <div class="mt-2 mx-2 d-flex align-center">
                <v-btn
                  block
                  depressed
                  class="text-capitalize rounded-lg bg_color1"
                  @click="createData"
                  dark
                >
                  <v-icon> mdi-plus</v-icon> Tambah
                </v-btn>
              </div>
            </v-col> -->
          <!-- </v-row> -->
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-4" align="center">
        <v-col cols="12" md="4" class="py-2">
          <bullet title="List Customer" />
        </v-col>
        <v-col cols="12" md="8">
          <v-row no-gutters align="center" justify="end">
            <div class="col_tollbar">
              <v-btn
                depressed
                class="text-capitalize btn_tollbar rounded-lg bg_color1"
                @click="createData"
                dark
              >
                <v-icon> mdi-plus</v-icon> Tambah
              </v-btn>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <v-card class="card_on_view" flat>
      <v-skeleton-loader
        class="pa-2 elevation-0"
        type="table-tbody"
        v-if="loading"
      ></v-skeleton-loader>
      <div v-if="!loading && dataSet">
        <v-data-table
          :headers="headers"
          :items="dataSet"
          hide-default-footer
          :page.sync="page"
          :items-per-page="limit"
          :single-select="false"
          class="elevation-0"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    depressed
                    class="mr-2"
                    @click="updateData(item)"
                  >
                    <v-icon dark small> mdi-pencil </v-icon>
                  </v-btn>
                </template>
                <span>Edit Akun Customer</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    depressed
                    color="red"
                    @click="deleteData(item)"
                  >
                    <v-icon dark small> mdi-delete </v-icon>
                  </v-btn></template
                >
                <span>Hapus Akun Customer</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
        <div class="text-center pa-3">
          <v-pagination
            color="#052633"
            v-model="page"
            @input="fetchData"
            :length="last_page"
          ></v-pagination>
        </div>
      </div>
    </v-card>
    <div class="pa-2">
      <dialog-medium
        :d_medium="d_create"
        :type="d_type"
        @close="d_create = false"
        :title="d_title"
        :inventory="inventory"
        :line="line"
        @refetch="fetchData"
      />
      <dialog-small
        :d_small="d_small"
        @refetch="fetchData"
        @close="d_small = false"
        :inventory="inventory"
        :type="d_type"
        :title="d_title"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import bullet from "../../components/Etc/bullet.vue";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  name: "listCustomer",
  components: { bullet, NavbarProfile },
  computed: {
    ...mapState({
      drawer: (state) => state.drawer,
      code_client: (state) => state.code_client,
    }),
  },
  data() {
    return {
      loading: false,
      dataSet: null,
      d_create: false,
      d_small: false,
      d_type: "",
      d_title: "",
      inventory: "",
      line: "",
      find: "",
      page: 1,
      limit: 10,
      selected: null,
      sort: "-created_at",
      filter: "",
      menuLimit: false,
      headers: [
        { text: "Nama", value: "nama_lengkap", width: "25%" },
        { text: "Instansi", value: "instansi" },
        { text: "Jabatan", value: "jabatan" },
        { text: "Kebutuhan Layanan", value: "kebutuhan_layanan" },
        { text: "Action", value: "actions", sortable: false },
      ],
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Manajemen Customer",
          disabled: true,
          href: "#",
        },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    createData() {
      this.d_type = "c_customer";
      this.d_title = "Tambah Akun Customer";
      this.d_create = true;
      this.line = true;
    },
    async fetchDataUpdate(data_item) {
      let tempSelected = JSON.parse(data_item.custom_profile);
      let data_update = [];
      let info_layanan_temp = false;
      let tempData = [];
      let data = {
        path: `registration/customer/${this.code_client}`,
      };
      await this.$store
        .dispatch("fileData/getData", data)
        .then((data) => {
          data.data.forEach((item) => {
            if (
              item.label === "Jangka Waktu" ||
              item.label === "Kebutuhan Layanan"
            ) {
              info_layanan_temp = true;
            }
            if (item.attribute) {
              if (item.attribute == "jangka_waktu") {
                if (typeof data_item["jangka_waktu"] === "string") {
                  const myArray = data_item["jangka_waktu"].split("-");
                  let start = this.$date(myArray[0]).format("YYYY-MM-DD");
                  let end = this.$date(myArray[1]).format("YYYY-MM-DD");
                  data_item[item.attribute] = [start, end];
                }
              }
              tempData.push({
                attribute: item.attribute,
                label: item.label,
                deleted: item.deleted,
                id: item.id,
                status: item.status,
                type: item.type,
                tipe: item.tipe,
                value: data_item[item.attribute],
                validator: item.validator,
              });
            } else {
              tempSelected.forEach((selected) => {
                if (Object.keys(selected)[0] == item.label) {
                  tempData.push({
                    attribute: item.attribute,
                    label: item.label,
                    deleted: item.deleted,
                    id: item.id,
                    status: item.status,
                    type: item.type,
                    tipe: item.tipe,
                    value: selected[item.label],
                    validator: item.validator,
                  });
                }
              });
            }
          });
          data_update = {
            id: data_item.id,
            data: tempData,
            id_klien: data.klien.id,
            info_layanan: info_layanan_temp,
          };
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
      return data_update;
    },
    async updateData(item) {
      this.inventory = await this.fetchDataUpdate(item);
      this.d_type = "u_customer";
      this.d_title = "Edit Akun Customer";
      this.d_create = true;
      this.line = true;
    },

    deleteData(item) {
      this.inventory = {
        data: item,
        path: `sysadmin/customer/delete`,
        deleteType: "Customer",
      };
      this.d_type = "delete";
      this.d_title = "Hapus Akun Customer";
      this.d_small = true;
      this.line = true;
    },
    changeSort(item) {
      this.sort = item;
      this.fetchData();
    },
    changeFilter(item) {
      this.filter = item;
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/customer/list?page[size]=${this.limit}&page[number]=${this.page}&sort=${this.sort}&filter[nama_lengkap]=${this.find}`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          this.dataSet = data.data;
          this.last_page = data.last_page;
          this.total_page = data.total;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    changeLimit(val) {
      this.limit = val;
      this.fetchData();
    },
  },
};
</script>

<style></style>
